// Package Imports
import React, { Component } from "react";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Formio, ReactComponent } from "react-formio";
import _ from "lodash";

// Product Imports
import cardDummyData from "../GridSummaryCardData";
import A8Utils from "../../../utils/A8Utils";

const GridSummaryCardCustomComp = (props) => {


    const { component, value, isBuilder } = props;
    const [ready, setReady] = useState(false);

    let debouncedApiCallHandler = useDebouncedCallback(apiCallHandler, 100);

    const [responseData, setResponseData] = useState(null)
    const [cardParametersSortedData, setCardParametersSortedData] = useState([])

    let formCardSummaryFields = {
        cardParametersList: component.cardParametersList,
        apiUrl: component.apiUrl,
        apiUrlParamsList: component.apiUrlParamsList,
        responseDataKey: component.responseDataKey,
    }

    function sortingGridData() {
        let sortedData = [];

        formCardSummaryFields.cardParametersList?.forEach(item => {
            const columnNumber = parseInt(item.parameterColumnNumber);
            if (!sortedData[columnNumber]) {
                sortedData[columnNumber] = []
            }
            sortedData[columnNumber].push(item);
        });
        sortedData.forEach(item => {
            if (item && Array.isArray(item)) {
                item.sort((a, b) => {
                    return parseInt(a.parameterPriorityInColumn) - parseInt(b.parameterPriorityInColumn)
                })
            }
        })

        setCardParametersSortedData(sortedData)
    }

    function getFormatParameterValue(row) {
        switch (row.parameterValueType) {
            case A8Utils.formBuilderFieldNames.text:
                return A8Utils.getColumnElementValue(responseData, row.ParameterKey)
            case A8Utils.formBuilderFieldNames.date:
                return A8Utils.formatDate(A8Utils.getColumnElementValue(responseData, row.ParameterKey))
            case A8Utils.formBuilderFieldNames.currency:
                return A8Utils.formatCurrency(A8Utils.getColumnElementValue(responseData, row.ParameterKey))
        
            default:
                A8Utils.getColumnElementValue(responseData, row.ParameterKey)
                break;
        }
    }

    function apiCallHandler() {
        if (isBuilder) {
            return
        }
        if (value) {
            let apiUrlParams = {};
            formCardSummaryFields.apiUrlParamsList?.forEach((item) => {
                apiUrlParams[item.apiUrlParameter] = A8Utils.getColumnElementValue(value, item.apiUrlTargetKey)
            })
            if (formCardSummaryFields.apiUrl) {
                let url = A8Utils.getAPiUrl(formCardSummaryFields.apiUrl, apiUrlParams)
                const token = localStorage.getItem('token');
                const tokenType = localStorage.getItem('tokenType')
                const modifiedConfig = {
                    headers: {
                      
                        'Content-Type': 'application/json',
                      ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
                    },
                  };
               
                
                fetch(url, {
                  
                    headers: modifiedConfig?.headers,
                   
                })
                    .then((res) => res.json())
                    .then((json) => {
                        let mappedResponse = A8Utils.getColumnElementValue(json,formCardSummaryFields.responseDataKey,'object') ;
                        setResponseData(mappedResponse)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
    }

    useEffect(() => {
        if (isBuilder) {
            setResponseData(cardDummyData)
        }
        else {
            debouncedApiCallHandler()
        }
    }, [])

    useEffect(() => {
        sortingGridData()
    }, [responseData])

    useEffect(() => {
        let state = true;
        if (isBuilder) {
            state = true;
        }
        if (value['oid']) {
            state = true;
        }
        setReady(state)
        console.log('value  useEffect', state, value['oid'])
    }, [value['oid']])

    if (!ready) {
        return <>Loadding!!!!</>
    }

    return (
        <>
            <div className=" bg-white border border-3 rounded-3 p-2">
                <div className="row">
                    <div className="col text-center">
                        <p className="text-uppercase fw-bold fs-5">
                            Summary
                        </p>
                    </div>
                </div>
                <div>
                    <div className="row">
                        { responseData &&
                            cardParametersSortedData?.map(
                                (col, colIndex) => (
                                    <div className="col text-center" key={'colIndex' + colIndex}>
                                        {col.map(
                                            (row, rowIndex) => {
                                                return (
                                                    <div className="" key={'colIndex' + colIndex + 'rowIndex' + rowIndex}>
                                                        <span>{row.parameterName}</span> :
                                                        <span className="fw-bold"> {getFormatParameterValue(row)}</span>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default GridSummaryCardCustomComp;